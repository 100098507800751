import cn from "@/utils/cn";
import { PrismicNextImage } from "@prismicio/next";
import { FeatureCardsSectionProps } from "../utils";
import { PrismicRichText } from "@prismicio/react";

type CardProps = {
  item: FeatureCardsSectionProps["slice"]["items"][0];
} & React.HTMLAttributes<HTMLDivElement>;

export default function Card({ item, className, ...props }: CardProps) {
  return (
    <div
      className={cn(
        "bg-white border border-[#EDF2F6] rounded-[13px] pb-[26px] px-[30px] md:px-[15px] xl:px-[22px]  w-[260px] min-h-[213px] md:w-[210px] xl:w-[352px] flex flex-col items-center xl:min-h-[326px] xl:items-start text-center ",

        className
      )}
      {...props}
    >
      <PrismicNextImage
        field={item.feature_icon}
        alt=""
        className={cn(
          "mt-12 mx-2 p-2 lg:p-0",
          item.icon_position === "left"
            ? "self-start"
            : item.icon_position === "right"
              ? "self-end"
              : "self-center"
        )}
      />
      <div className="mt-4 w-full">
        <PrismicRichText
          field={item.feature_title}
          components={{
            paragraph: ({ children }) => {
              return (
                <p className="text-center text-[12px] leading-[20.8px] font-semibold  text-kd-lte-blue xl:text-[20px]  xl:leading-[32px]">
                  {children}
                </p>
              );
            },
          }}
        />
      </div>
      <div className="mt-2">
        <PrismicRichText
          field={item.feature_description}
          components={{
            paragraph: ({ children }) => {
              return (
                <p className="text-[9px] leading-[18px]  xl:leading-[20px] xl:text-[14px] xl:leading-[28px] text-[#444A6D]">
                  {children}
                </p>
              );
            },
          }}
        />
      </div>
    </div>
  );
}
