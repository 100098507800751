import FeatureCardsSectionInternal from "./feature-cards-section-internal";
import { FeatureCardsSectionProps } from "./utils";

/**
 * Component for "FeatureCardsSection" Slices.
 */
const FeatureCardsSection = ({
  slice,
}: FeatureCardsSectionProps): JSX.Element => {
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
    >
      <FeatureCardsSectionInternal
        contentTitle={slice.primary.title}
        contentDescription={slice.primary.description}
        cardItems={slice.items}
        imagePosition={slice.primary.background_image_position}
      />
    </section>
  );
};

export default FeatureCardsSection;
