import cn from "@/utils/cn";

import Card from "./card";
import { PrismicRichText } from "@prismicio/react";
import { FeatureCardsSectionProps } from "../utils";
import BgRightImage from "@/assets/bg-right-image.svg";
import BgLeftImage from "@/assets/bg-left-image.svg";
import Image from "next/image";

type FeatureCardsSectionInternalProps = {
  contentTitle: FeatureCardsSectionProps["slice"]["primary"]["title"];
  contentDescription: FeatureCardsSectionProps["slice"]["primary"]["description"];
  cardItems: FeatureCardsSectionProps["slice"]["items"];
  imagePosition: FeatureCardsSectionProps["slice"]["primary"]["background_image_position"];
} & React.HTMLAttributes<HTMLDivElement>;

export default function FeatureCardsSectionInternal({
  contentTitle,
  contentDescription,
  cardItems,
  imagePosition,
  className,
  ...props
}: FeatureCardsSectionInternalProps) {
  return (
    <div
      className={cn(
        "py-[56px] md:py-[66px]  bg-[#FAFBFC]  overflow-hidden relative  ",
        className
      )}
      {...props}
    >
      <div className="max-w-[1120px] mx-auto px-[39px] md:px-[36px] lg:px-20 xl:px-0 flex-col flex items-center">
        {imagePosition !== "None" && (
          <>
            {imagePosition === "Right" ? (
              <Image
                src={BgRightImage}
                alt="background right"
                // className="h-full"
                className="absolute top-0 right-0 bottom-0 -z-0 h-full hidden md:block"
              />
            ) : (
              <Image
                src={BgLeftImage}
                alt="background right"
                className="absolute top-0 left-0 bottom-0 -z-0 h-full hidden md:block"
              />
            )}
          </>
        )}

        <div className="flex flex-col items-center z-0">
          <div className="mb-2 text-center xl:mb-4">
            <PrismicRichText
              field={contentTitle}
              components={{
                paragraph: ({ children }) => {
                  return (
                    <p className="text-[22px] font-semibold text-kd-lte-blue leading-[30px] md:text-[27px] md:leading-[33px] xl:text-[40px] xl:leading-[51px]">
                      {children}
                    </p>
                  );
                },
              }}
            />
          </div>
          <PrismicRichText
            field={contentDescription}
            components={{
              paragraph: ({ children }) => {
                return (
                  <p
                    // className="text-center text-[11px] font-medium leading-[18.70px] text-[#444A6D] md:w-[55%] xl:text-base xl:leading-6 "
                    className="text-[10.8px] md:text-[10.8px] font-medium xl:text-[14px] leading-[18px] md:leading-[18px] xl:leading-[24px] text-[#444A6D] text-center md:w-[55%]"
                  >
                    {children}
                  </p>
                );
              },
            }}
          />
        </div>
        {/* <div className="my-5 flex flex-col justify-center md:flex-row md:space-x-12 xl:my-8 xl:space-x-16"> */}
        <div className="flex flex-col md:flex-row gap-[21px] lg:gap-[32px] mt-[26px] z-10">
          {cardItems.map((item, index) => (
            <Card className="" key={index} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
}
